import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { getStones, getStoneImages } from "../model/firebase"
import Loading from "../components/loading"
import LoadedData from "../model/loadedData"
import StoneData from "../model/stoneData"

const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Home" />
    <h2>Seznam kamínků</h2>
    <Stones/>
  </Layout>)
}

const Stones = () => {
  const [stonesData, setStonesData] = useState<LoadedData<Array<StoneData>>>({
    loading: false,
    data: []
  });

  useEffect(() => {
    setStonesData({...stonesData, loading: true});
    return getStones().limit(10).onSnapshot(snapshot => {
      let stones: Array<StoneData> = [];
 
      snapshot.forEach(doc =>
        stones.push({ ...doc.data(), uid: doc.id }),
      );

      setStonesData({...stonesData, data: stones})
    })
  }, []);

  if (stonesData.loading)
    return (
      <Loading/>
    )

  return(
    <div style={{
      display: 'grid',
      gridGap: '1rem',
      gridTemplateColumns: 'repeat(auto-fill, minmax(30ex, 1fr))'
    }}>
    { stonesData.data.map( stone => <Stone key={stone.uid} stone={stone}/>) }
    </div>
  )
}

const Stone = (props: {stone: StoneData}) => {
  const [imageSrc, setImageSrc] = useState('http://placehold.it/512');

  useEffect(() => {
    getStoneImages(props.stone.uid)
      .getThumbnail()
      .then(setImageSrc)
  }, [])

  return (
    <Link to={`/stone?id=${props.stone.uid}`}
    style={{
      height: '40ex',
      backgroundImage: 'url("' + imageSrc + '")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative'
    }}>
      <div style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        padding: '10px',
        backgroundColor: '#EEE8',
        color: 'black'
      }}>Stone {props.stone.uid}</div>
      {/* {JSON.stringify(props.stone)} */}
    </Link>
  )
}

export default IndexPage
